<template>
  <div class="container settings">
    <p class="alert alert-primary">
        <i class="fad fa-info-circle mr-1"></i>
        Configure which email notifications you would like to receive.
    </p>
    <div class="row">
      <div class="col">
          <div class="card">
              <div class="card-body">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="emailSync"
                             v-model="user.email_settings.enable_client_system_emails"
                             @change="updateEmailSettings">
                      <label class="custom-control-label" for="emailSync">Enable Automated Email Notifications</label>
                  </div>
                  <div class="ml-3 mt-2" v-if="user.email_settings.enable_client_system_emails">
                      <div class="custom-control custom-switch mb-3">
                          <input type="checkbox" class="custom-control-input" id="appointments"
                                 v-model="user.email_settings.enable_appointment_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="appointments">
                              Appointment Notifications
                          </label>
                      </div>

                      <div class="custom-control custom-switch mb-3">
                          <input type="checkbox" class="custom-control-input" id="files"
                                 v-model="user.email_settings.enable_files_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="files">
                              File Share Notifications
                          </label>
                      </div>

                      <div class="custom-control custom-switch mb-3">
                          <input type="checkbox" class="custom-control-input" id="planner"
                                 v-model="user.email_settings.enable_health_planner_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="planner">
                              Health Planner Notifications
                          </label>
                      </div>

                      <div class="custom-control custom-switch mb-3">
                          <input type="checkbox" class="custom-control-input" id="mymop"
                                 v-model="user.email_settings.enable_mymop_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="mymop">
                              MyMop Notifications
                          </label>
                      </div>

                      <div class="custom-control custom-switch mb-3">
                          <input type="checkbox" class="custom-control-input" id="orders"
                                 v-model="user.email_settings.enable_order_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="orders">
                              Order Notifications
                          </label>
                      </div>

                      <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="resources"
                                 v-model="user.email_settings.enable_resources_emails"
                                 @change="updateEmailSettings">
                          <label class="custom-control-label" for="resources">
                              Resource Share Notifications
                          </label>
                      </div>
                  </div>

              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    updateEmailSettings() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/update-email-settings", this.user)
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    }
  }
};
</script>

<style>
</style>
